<template>
	<div class="my-container p-all-20">
		<myHeader :title="i18n.wdtd" :height="500">
			<template slot="content">
				<div class="team-box p-bottom">
					<div class="team-stat max-bg d-around-row">
						<div>
							<span class="f-40">{{ params.group }}</span>
							<span>{{i18n.zrs}}</span>
						</div>
						<div>
							<span class="f-40">{{ params.groupAmount }}</span>
							<span>{{i18n.zyj}}</span>
						</div>
						<div>
							<span class="f-40">{{ params.other }}</span>
							<span>{{i18n.xyj}}</span>
						</div>
					</div>
					<div class="stat-box">
						<div class="s-title d-space-row m-top-bottom">
							<span>{{i18n.wdzt}}</span>
						</div>
						<div class="stat-list">
							<div class="s-label d-space-row">
								<div>
									<span>{{i18n.rq}}</span>
								</div>
								<div>
									<span>{{i18n.dz}}</span>
								</div>
								<div>
									<span>{{i18n.lx}}</span>
								</div>
							</div>
								<template v-for="(item, index) in list">
									<myTeamItem :item="item"></myTeamItem>
								</template>
						</div>
					</div>
				</div>
			</template>
		</myHeader>
	</div>
</template>

<script>
import { groupParams, children } from "@/api/account.js";
import myTeamItem from '../components/myTeamItem.vue'
export default {
	components: {
		myTeamItem
	},
	data() {
		return {
			params:{},
			list: [],
		};
	},
	computed: {
		i18n() {
			return this.$t('my')
		}
	},
	created() {
		this.getParams()
		this.getList()
	},
	methods: {
		async getParams() {
			const {
				err,
				res
			} = await groupParams();
			if (err === null) {
				this.params = res;
			}
		},
		async getList() {
			const {
				err,
				res
			} = await children();
			if (err === null) {
				this.list = res;
			}
		},
	}

}
</script>

<style lang="scss" scoped>
.my-container {
	.team-box {
		.team-stat {
			width: 643px;
			height: 233px;
			background: url('../../assets/image/team-bg.png');

			&>div {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				text-align: center;
				span:nth-child(1) {
					color: #F1FF58;
				}
			}
		}

		.stat-box {
			width: 100%;
			margin-top: 70px;

			.s-title {
				justify-content: flex-start;
			}

			.stat-list {
				width: 100%;
				height: calc(100vh - 620px);
				background: rgba(82, 121, 42, 0.4);
				box-shadow: 0px 0px 13px 0px rgba(65, 126, 150, 0.25);
				border-radius: 8px;
				position: relative;
				box-sizing: border-box;
				padding: 10px;
				overflow: scroll;

				.s-label {
					width: 100%;
					height: 78px;
					background: rgba(203, 203, 222, 0.2);
					border-radius: 8px;

					&>div {
						width: 33.3%;
						font-size: 24px;
						text-align: center;
					}
				}

				.van-list {
					width: 100%;
					height: calc(100vh - 720px);
					overflow: scroll;
				}
			}
		}
	}
}
</style>