<template>
	<div class="item-container">
		<div class="team-item d-space-row">
			<div>
				<span>{{ item.createTime.substring(0,11) }}</span>
			</div>
			<div>
				<span>{{ item.address.substring(0,6) }}**{{ item.address.substring(item.address.length-6,item.address.length) }}</span>
			</div>
			<div>
				<span v-if="item.isActive == 1">{{i18n.yx}}</span>
				<span v-if="item.isActive == 2">{{i18n.wx}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
			},
		},
		data() {
			return {};
		},
		computed: {
			i18n() {
				return this.$t('my')
			}
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.item-container {
		width: 100%;

		.team-item {
			width: 100%;
			height: 78px;
			box-sizing: border-box;
			border-bottom: 20px;

			&>div {
				width: 33.3%;
				font-size: 22px;
				color: #B1EC9A;
				text-align: center;
			}
		}
	}
</style>